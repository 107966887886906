<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_783_17854)">
            <path d="M14.7559 2.06845C13.8057 1.2777 12.702 0.692326 11.5143 0.349229C10.3266 0.00613223 9.08074 -0.087254 7.85519 0.0749549C5.58799 0.369493 3.51786 1.51583 2.06513 3.28121C0.612409 5.04659 -0.114007 7.29867 0.0333839 9.58017C0.180775 11.8617 1.19092 14.0016 2.85872 15.5653C4.52651 17.1291 6.72694 17.9996 9.01319 18H14.2497C15.2439 17.9988 16.197 17.6033 16.9 16.9003C17.603 16.1973 17.9985 15.2442 17.9997 14.25V8.48171V8.43446C17.9203 7.20854 17.5917 6.01169 17.034 4.91712C16.4762 3.82254 15.7011 2.85322 14.7559 2.06845ZM5.99969 5.24996H8.99969C9.19861 5.24996 9.38937 5.32897 9.53002 5.46962C9.67068 5.61028 9.74969 5.80104 9.74969 5.99996C9.74969 6.19887 9.67068 6.38963 9.53002 6.53029C9.38937 6.67094 9.19861 6.74996 8.99969 6.74996H5.99969C5.80078 6.74996 5.61001 6.67094 5.46936 6.53029C5.32871 6.38963 5.24969 6.19887 5.24969 5.99996C5.24969 5.80104 5.32871 5.61028 5.46936 5.46962C5.61001 5.32897 5.80078 5.24996 5.99969 5.24996ZM11.9997 12.75H5.99969C5.80078 12.75 5.61001 12.6709 5.46936 12.5303C5.32871 12.3896 5.24969 12.1989 5.24969 12C5.24969 11.801 5.32871 11.6103 5.46936 11.4696C5.61001 11.329 5.80078 11.25 5.99969 11.25H11.9997C12.1986 11.25 12.3894 11.329 12.53 11.4696C12.6707 11.6103 12.7497 11.801 12.7497 12C12.7497 12.1989 12.6707 12.3896 12.53 12.5303C12.3894 12.6709 12.1986 12.75 11.9997 12.75ZM11.9997 9.74996H5.99969C5.80078 9.74996 5.61001 9.67094 5.46936 9.53029C5.32871 9.38963 5.24969 9.19887 5.24969 8.99996C5.24969 8.80104 5.32871 8.61028 5.46936 8.46963C5.61001 8.32897 5.80078 8.24996 5.99969 8.24996H11.9997C12.1986 8.24996 12.3894 8.32897 12.53 8.46963C12.6707 8.61028 12.7497 8.80104 12.7497 8.99996C12.7497 9.19887 12.6707 9.38963 12.53 9.53029C12.3894 9.67094 12.1986 9.74996 11.9997 9.74996Z" fill="url(#paint0_linear_783_17854)" />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_783_17854"
                x1="9.00717"
                y1="-0.00244141"
                x2="9.00717"
                y2="18"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath id="clip0_783_17854">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
